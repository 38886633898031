import React, { useEffect, useState } from 'react'

import './styles.scss'

import { AutoComplete, Button, Carousel, Divider, Input, Modal, Select, Tabs } from 'antd'
import { AppstoreOutlined, ArrowRightOutlined, DownOutlined, FacebookFilled, InstagramFilled, LoadingOutlined, SearchOutlined, YoutubeFilled } from '@ant-design/icons';
import { FiCompass } from 'react-icons/fi';
import { TbBuildingStadium, TbCactus, TbDirections, TbPlaneTilt, TbPlayFootball } from 'react-icons/tb';
import SecAllDestinations from './SecAllDestinations';
import SecTourPackages from './SecTourPackages';
import SecTripPlanner from './SecTripPlanner';
import SecAboutUs from './SecAboutUs';
import SecContactUs from './SecContactUs';
import { searchTourPackages } from '../../../redux/api/tourPackages.api';
import { DEFAULT_THUMBNAIL } from '../../constants/otherConstsnts';
import { searchActivities } from '../../../redux/api/activities.api';
import { searchEvents } from '../../../redux/api/events.api';
import { searchDestinations } from '../../../redux/api/destinations.api';
import { useNavigate } from 'react-router-dom';


const tabItems = [
    {
        key: 'tours',
        label: 'Tours'
    },
    {
        key: 'activities',
        label: 'Things to do'
    },
    {
        key: 'events',
        label: 'Events'
    },
    {
        key: 'destinations',
        label: 'Destinations'
    },
]

const homeBGImages = [
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg1.webp',
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg2.webp',
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg3.webp',
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg4.webp',
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg5.webp',
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg6.webp',
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg7.webp',
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg8.webp',
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg9.webp',
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg10.webp',
    'https://visitpearlsharedblob.blob.core.windows.net/appassets/bgimg11.webp',
]

const Home = () => {
    const [selectedSearchTab, setSelectedSearchTab] = useState(tabItems[0].key)
    const [searchResults, setSearchResults] = useState([])
    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const navigate = useNavigate()

    const handleSearch = async (value) => {
        setIsSearchLoading(true)
        try {
            if (selectedSearchTab === 'tours') {
                const response = await searchTourPackages(value)
                addSearchResultTours(response.data)
            } else if (selectedSearchTab === 'activities') {
                const response = await searchActivities(value)
                addSearchResultActivities(response.data)
            } else if (selectedSearchTab === 'events') {
                const response = await searchEvents(value)
                addSearchResultEvents(response.data)
            } else if (selectedSearchTab === 'destinations') {
                const response = await searchDestinations(value)
                addSearchResultDestinations(response.data)
            }

        } catch (error) {
            console.error('Search Error', error)
        }

        setIsSearchLoading(false)
    }

    const onChangeSearch = (value) => {
        if (value === '') {
            setSearchResults([])
        }

        if (value.length < 3) {
            setSearchResults([])
            return
        }

        handleSearch(value)
    }

    const addSearchResultTours = (items) => {
        const results = items.map(item => {
            return {
                value: item.id,
                label:
                    <div className='result-row'
                        onClick={() => navigate(`/tours/${item.id}`)}
                    >
                        <div className='result-row-info'>
                            <h3>{item.packageName}</h3>
                            <p>{item.tourType}</p>
                        </div>
                    </div>
            }
        })

        if (results.length === 0) {
            results.push({
                value: 'no-results',
                label: <div className='result-row no_result'>
                    <div className='empty'>
                        <TbCactus /> <p>No Results Found</p>
                    </div>
                </div>
            })
        }

        setSearchResults(results)
    }

    const addSearchResultActivities = (items) => {
        const results = items.map(item => {
            return {
                value: item.id,
                label:
                    <div className='result-row-activities'
                        onClick={() => navigate(`/destinations/${item.destinationId}/activities/${item.id}`)}
                    >
                        <div className='left'>
                            <img
                                src={item.thumbnailUrl || DEFAULT_THUMBNAIL}
                                alt={item.activityName}
                                loading='lazy'
                            />
                        </div>

                        <div className='right'>
                            <h3>{item.activityName}</h3>
                            <p>{item.activityType}</p>
                        </div>
                    </div>
            }
        })

        if (results.length === 0) {
            results.push({
                value: 'no-results',
                label: <div className='result-row no_result'>
                    <div className='empty'>
                        <TbCactus /> <p>No Results Found</p>
                    </div>
                </div>
            })
        }

        setSearchResults(results)
    }

    const addSearchResultEvents = (items) => {
        const results = items.map(item => {
            return {
                value: item.id,
                label:
                    <div className='result-row-activities'
                        onClick={() => navigate(`/destinations/${item.destinationId}/events/${item.id}`)}
                    >
                        <div className='left'>
                            <img
                                src={item.thumbnailUrl || DEFAULT_THUMBNAIL}
                                alt={item.eventName}
                                loading='lazy'
                            />
                        </div>

                        <div className='right'>
                            <h3>{item.eventName}</h3>
                            <p>{item.eventCity}</p>
                        </div>
                    </div>
            }
        })

        if (results.length === 0) {
            results.push({
                value: 'no-results',
                label: <div className='result-row no_result'>
                    <div className='empty'>
                        <TbCactus /> <p>No Results Found</p>
                    </div>
                </div>
            })
        }

        setSearchResults(results)
    }

    const addSearchResultDestinations = (items) => {
        const results = items.map(item => {
            return {
                value: item.id,
                label:
                    <div className='result-row-activities'
                        onClick={() => navigate(`/destinations/${item.id}`)}
                    >
                        <div className='left'>
                            <img
                                src={item.thumbnailUrl || DEFAULT_THUMBNAIL}
                                alt={item.destinationName}
                                loading='lazy'
                            />
                        </div>

                        <div className='right'>
                            <h3>{item.destinationName}</h3>
                            <p>{item.destinationCity}</p>
                        </div>
                    </div>
            }
        })

        if (results.length === 0) {
            results.push({
                value: 'no-results',
                label: <div className='result-row no_result'>
                    <div className='empty'>
                        <TbCactus /> <p>No Results Found</p>
                    </div>
                </div>
            })
        }

        setSearchResults(results)
    }

    return (
        <div className='main__contaier home'>
            <div className='main__hero'>
                <div className='hero__background__overlay'></div>
                <Carousel
                    className='hero__background'
                    autoplay
                    effect='fade'
                    dots={false}
                >
                    {homeBGImages.map((image, index) => (
                        <img
                            src={image}
                            alt='bg'
                            key={index}
                            loading='lazy'
                        />
                    ))}
                </Carousel>
                <section className='section__1'>
                    <div className='hero__top'>
                        <h1 data-aos="fade-up" className='title'>Visit
                            {window.innerWidth < 768 ? <br /> : ' '}
                            Sri Lanka</h1>

                        <div className='hero_search'>
                            <Tabs
                                data-aos="fade-up"
                                data-aos-delay="300"
                                className='search__tabs__ant'
                                defaultActiveKey={selectedSearchTab}
                                items={tabItems}
                                onChange={(key) => {
                                    setSelectedSearchTab(key)
                                    setSearchResults([])
                                }}
                            />

                            <div className='hero__search__button' data-aos="fade-up" data-aos-delay="600">
                                <AutoComplete
                                    popupClassName='certain-category-search-popup'
                                    options={searchResults}
                                >
                                    <Input
                                        className='search__input'
                                        prefix={isSearchLoading ? <LoadingOutlined /> : <SearchOutlined />}
                                        placeholder={`Search ${tabItems.find(item => item.key === selectedSearchTab).label}`}
                                        onChange={(e) => onChangeSearch(e.target.value)}
                                    />
                                </AutoComplete>
                                <Button
                                    type='primary'
                                    onClick={() => handleSearch()}
                                    className='serach_btn'
                                    loading={isSearchLoading}
                                    icon={<SearchOutlined />}
                                >
                                    Search
                                </Button>
                            </div>
                        </div>

                        <div className='mobile__hero_search' data-aos="fade-up"
                            data-aos-delay="300">
                            <div className='mobile__hero_search__inner'>
                                <Select
                                    className='search__select'
                                    defaultValue='tours'
                                    suffixIcon={<DownOutlined />}
                                    onChange={(value) => {
                                        setSelectedSearchTab(value)
                                        setSearchResults([])
                                    }}
                                    placeholder={`Search ${tabItems.find(item => item.key === selectedSearchTab).label}`}
                                    popupClassName='search__select__popup'
                                >
                                    <Select.Option value='tours'><FiCompass /> Tours</Select.Option>
                                    <Select.Option value='activities'><TbPlayFootball /> Things to do</Select.Option>
                                    <Select.Option value='events'><TbBuildingStadium /> Events</Select.Option>
                                    <Select.Option value='destinations'><TbDirections /> Destinations</Select.Option>
                                    <Select.Option value='airtickets'><TbPlaneTilt /> Air Tickets</Select.Option>
                                </Select>

                                <Divider />

                                <AutoComplete
                                    popupClassName='certain-category-search-popup'
                                    options={searchResults}
                                >
                                    <Input
                                        className='search__input'
                                        prefix={isSearchLoading ? <LoadingOutlined /> : <SearchOutlined />}
                                        placeholder={`Search ${tabItems.find(item => item.key === selectedSearchTab).label}`}
                                        onChange={(e) => onChangeSearch(e.target.value)}
                                    />
                                </AutoComplete>

                                <Divider />


                                <Button
                                    type='primary'
                                    onClick={() => handleSearch()}
                                    className='serach_btn'
                                >
                                    Search
                                </Button>
                            </div>
                        </div>



                    </div>
                    <div className='hero__top__down'>
                        <div className='social__media' data-aos="fade-right" data-aos-delay="600">
                            <a href='https://www.facebook.com/profile.php?id=61560704055422' target='_blank' rel='noreferrer'> <FacebookFilled /></a>
                            <a href='https://www.instagram.com/visit.pearl' target='_blank' rel='noreferrer'> <InstagramFilled /></a>
                            <a href='https://www.youtube.com/@VisitPearl' target='_blank' rel='noreferrer'> <YoutubeFilled /></a>
                        </div>
                        <div className='hero__top__down__right' data-aos="fade-left" data-aos-delay="600">
                            <div className='small_cards_container'>
                                <div className='custom__card'>
                                    <h3>Trip Planner</h3>
                                    <h2>Make your own trip plan</h2>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eleifend sodales mattis.
                                    </p>
                                </div>

                                <div className='custom__card'>
                                    <h3>Booking</h3>
                                    <h2>Hotels, Transport & more...</h2>
                                    <p>
                                        Pellentesque condimentum gravida dapibus. Morbi laoreet ligula at placerat venenatis.
                                    </p>
                                </div>

                                <div className='custom__card'>
                                    <h3>Fly to Sri Lanka</h3>
                                    <h2>Make your own trip plan</h2>
                                    <p>
                                        Morbi laoreet suscipit tortor, et tincidunt justo rhoncus vel.
                                    </p>
                                </div>
                            </div>
                            <div className='big__card' >
                                <h2>Jump to Hill Country</h2>
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    className='hero__video'
                                    loading='lazy'
                                    controls
                                >
                                    <source src='https://visitpearlsharedblob.blob.core.windows.net/appassets/bridge-hero.mp4' type='video/mp4' />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                        </div>
                    </div>

                    <div className="custom-shape-divider-top-1724082403">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                        </svg>
                    </div>
                </section>

                <div className="hero__bottom">

                    <div className='hero__bottom__content body_content'>
                        <div className='hero__bottom__content__left'>
                            <h1 data-aos="fade-right" >Your next holiday in</h1>
                            <h2 data-aos="zoom-in">Sri Lanka</h2>
                            <p data-aos="fade-up" data-aos-delay="200">
                                Sri Lanka, often called the Pearl of the Indian Ocean, is a dream destination for travelers seeking a mix of adventure, relaxation, and cultural richness. This tropical island offers an incredible variety of experiences in a compact space, from sun-soaked beaches to breathtaking mountain landscapes. Whether you’re exploring its vibrant cities, unwinding on pristine shores, or venturing into lush jungles, Sri Lanka promises something special for every kind of traveler.
                            </p>
                            <p data-aos="fade-up" data-aos-delay="200">
                                Known for its warm, welcoming people and rich traditions, Sri Lanka provides an immersive cultural experience. You’ll be enchanted by its colorful festivals, delicious cuisine, and ancient temples that whisper tales of a bygone era. With its year-round tropical climate, diverse wildlife, and opportunities for adventure whether it’s surfing, hiking, or wildlife safaris Sri Lanka is a destination that guarantees unforgettable memories. Experience the best of nature, culture, and hospitality in one magical island.
                            </p>
                            <div className='hero__bottom__content__left__button' data-aos="fade-up" data-aos-delay="400">
                                <Button
                                    className='custom_primary_btn_large' type='primary'
                                    onClick={() => navigate('/destinations')}
                                >
                                    <AppstoreOutlined /> Explore
                                </Button>
                                <Button
                                    className='custom_primary_btn_link'
                                    type='link'
                                    onClick={() => navigate('/about-sri-lanka')}
                                >
                                    Learn More <ArrowRightOutlined />
                                </Button>
                            </div>
                        </div>
                        <div className='hero__bottom__content__right' >
                            <img data-aos="fade-left" data-aos-delay="400" src='https://visitpearlsharedblob.blob.core.windows.net/appassets/srilankamap.webp' alt='sri lanka map' loading='lazy' />
                        </div>
                    </div>
                </div>
            </div>

            <SecAllDestinations />

            <SecTourPackages />

            <SecTripPlanner />

            {/* <SecMobileBanner /> */}

            <SecAboutUs />

            {/* <SecTestamonial /> */}

            <SecContactUs />
        </div >
    )
}

export default Home